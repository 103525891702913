
import "../../assets/css/styles.css"
import { CreateWikiHeader } from "../Wiki"



function UinderalCreaturesRacesEntities() {
    return (
        <div class="Container">
            {CreateWikiHeader()}
            <div className="d-flex flex-column p-5 mb-5 justify-content-center align-items-center">
                <h1 className="mb-3">Section underwork</h1>
            </div>
        </div>
    );
}

export default UinderalCreaturesRacesEntities;